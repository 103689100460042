<template>
  <div class="map-container">
    <GmapMap
      ref="gmap"
      :center="mapCenter"
      :zoom="4"
      :options="mapOptions"
      style="width: 100%; height: 93vh"
    >
      <gmap-custom-marker
        v-for="(v, index) in list"
        :key="index"
        :marker="{ lat: v.latitude, lng: v.longitude }"
      >
        <div
          class="pin-icon-container"
          @click="showInfo(v)"
        >
          <div
            class="pin-title"
            :title="v.name"
          >
            {{ v.name }}
          </div>
          <div class="pin-circle active">
            <v-icon>mdi-ev-station</v-icon>
          </div>
          <div class="pin-arrow" />
        </div>
      </gmap-custom-marker>
      <GmapInfoWindow
        :position="infoWindowPosition"
        :opened="infoWindowOpened"
        @closeclick="infoWindowOpened = false"
      >
        <div class="info-window">
          <div>
            <span>Tenant Name:</span>
            <span>{{ infoWindowContent.tenant_name }}</span>
          </div>
          <div>
            <span>Address:</span> <span>{{ infoWindowContent.address }}</span>
          </div>
          <div>
            <span>Contact:</span>
            <span>
              {{ infoWindowContent.country_code }}-{{
                infoWindowContent.phone_number
              }}
            </span>
          </div>

          <div>
            <span>Status:</span>
            <span>
              <status-badge :status="infoWindowContent.location_status" />
              {{ infoWindowContent.location_status }}
            </span>
          </div>
          <div>
            <span>Timings:</span> <span>{{ infoWindowContent.timings }}</span>
          </div>
        </div>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import GmapCustomMarker from "vue2-gmap-custom-marker";
export default {
  components: {
    GmapCustomMarker,
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  data() {
    return {
      mapCenter: { lat: 30.3753, lng: 69.3451 },
      mapOptions: {
        minZoom: 4, // Restrict zooming out beyond level 4
        maxZoom: 18, // Optional: Restrict max zoom-in
        scaleControl: true,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: window.google?.maps.ControlPosition.BOTTOM_RIGHT, // Place at right-bottom
        },
      },
      infoWindowOpened: false,
      infoWindowPosition: null,
      infoWindowContent: "",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsLocations/getLoading",
      list: "csmsLocations/getList",
    }),
  },
  mounted() {
    this.fetchData();
    this.initMap();
  },
  methods: {
    initMap() {
      this.$refs.gmap.$mapPromise.then(() => {
        // this.setMapBounds();
        if (window.google && window.google.maps) {
          this.mapOptions.fullscreenControlOptions = {
            position: window.google.maps.ControlPosition.BOTTOM_RIGHT, // Correct placement
          };
        }
      });
    },
    async fetchData() {
      const params = {};
      const usr = this.authUser;
      if (!usr.isAdmin) {
        params.tenant_id = usr.independent_fleet_user_nature
          ? usr.tenant_id
          : usr.parent_tenant_id;
      }

      await this.$store.dispatch("csmsLocations/list", params);
      // .then(() => this.setMapBounds());
    },
    showInfo(v) {
      this.infoWindowPosition = {
        lat: parseFloat(v.latitude),
        lng: parseFloat(v.longitude),
      };
      this.infoWindowContent = v;
      this.infoWindowOpened = true;
    },
    setMapBounds() {
      this.$refs.gmap.$mapPromise.then((map) => {
        if (!this.list.length) return;

        const bounds = new window.google.maps.LatLngBounds();
        this.list.forEach((v) => {
          bounds.extend(new window.google.maps.LatLng(v.latitude, v.longitude));
        });

        map.fitBounds(bounds);

        setTimeout(() => {
          let currentZoom = map.getZoom();
          if (currentZoom < 4) {
            map.setZoom(4); // Prevent excessive zooming in
          }
        }, 500);
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.map-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.pin-icon-container
  user-select: none
  position: relative
  width: 40px
  height:  60px
  .pin-title
    position: absolute
    text-align: center
    top: -25px
    left: -10px
    background: #20a390
    border-radius: 2px
    width: 65px
    font-size: 12px
    font-weight: 800
    padding: 2px
    /* text-wrap: nowrap; */
    overflow: hidden
    text-overflow: ellipsis
    display: flex
    /* justify-content: center; */
    white-space: nowrap
    display: inline-block
    box-shadow: 2px 2px 10px 2px gray
  .pin-circle
    padding: 5px
    width: 40px
    height: 40px
    background-color: #20a390
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    color: white
    font-size: 14px
    font-weight: bold
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3)
    i
      color: #fff !important
  .pin-arrow
    position: absolute
    top: 40px
    left: 15px
    width: 10px
    height: 20px
    background-color: #20a390
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%)


.info-window
  div
    display: flex
    justify-content: space-between
    align-items: center
    gap: 1rem
    span:last-child
      font-weight: 600

  :deep .status-badge
    display: inline-block
</style>
